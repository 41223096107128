import Vue from "vue";
import Vuex from "vuex";
import http from "@/plugins/axios";

Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        loader:false,
        localization:[],
        passwordModal:false
    },
    mutations:{
        loader(state,value){
            state.loader = value
        },
        changeLocale(state,locale){
            state.localization = locale;
        },
        passwordModal(state,value){
            state.passwordModal = value;
        }
    },
    getters:{
        getLocale(state){
            return state.localization
        },
        getContentLocale(state) {
            return state.localization ? state.localization : [];
        },
    },
    actions:{
        async getValuesByLang() {
            let val = 'front_end';
            return await http.get(`localizations/groups/items?keyword=${val}`);
        },
    }

})