import axios from "axios";
import TokenService from "../services/TokenService";
import router from "../router/index";

window.axios = axios;
// export const baseURL = "http://10.0.40.55:7170";
export const baseURL = "https://admin.smartbill.uz"

// export const baseURL = "http://10.0.40.108:7170"wqdwqdwqdqw



const http = axios.create({
    baseURL: baseURL + '/api/'
});

function setConfiguration(provider) {
    provider.interceptors.request.use(config => {
            let token = TokenService.getToken();
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            config.headers['Accept'] = 'application/json';
            config.headers['Content-Type'] = 'application/json';
            config.headers['Language'] = localStorage.getItem('lang');
            return config;
        },
        error => Promise.reject(error)
    );
    provider.interceptors.response.use(res => res.data,
        error => {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                router.push({name: 'app-login'}).then(() => {
                    TokenService.removeToken();
                }).catch(e => {
                    console.log(e)
                })
            }
            return Promise.reject(error)
        });
}

setConfiguration(http)


export default http
