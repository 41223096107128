<template>
<div id="app">
  <router-view/>
  <notifications group="foo" />
  <app-loader v-if="$store.state.loader"/>
  
</div>
</template>

<script>
import loader from "@/components/Loader/loader.vue";
export default {
  name: 'App',
  components:{
    'app-loader': loader
  },
  methods:{
    async getLocalizedValues() {
      try {
        const res = await this.$store.dispatch('getValuesByLang');
        this.$store.commit('changeLocale', res.result);
      } catch (e) {
        this.errorNotify(e?.response?.data?.error?.message ?? e)
      }
    },
  },
  async mounted() {
    await this.getLocalizedValues()
  }

}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
overflow-x: hidden;
}
</style>
