<template>
  <div class="header-view">
    <div class="search-content">
      <el-input placeholder="Search or type a command" v-model="search" class="search-input"
                prefix-icon="el-icon-search">

      </el-input>
    </div>
    <div>
      <div class="header-right">
        <!--     <el-dropdown trigger="click" :hide-on-click="false" size="small " placement="bottom-start">-->
        <!--  <span class="el-dropdown-link">-->
        <!--    <el-badge :value="1" class="item" type="primary">-->
        <!--       <i class="icon-bell"></i>-->
        <!--     </el-badge>-->
        <!--  </span>-->
        <!--       <el-dropdown-menu class="notification-menu">-->
        <!--         <el-dropdown-item >small </el-dropdown-item>-->

        <!--       </el-dropdown-menu>-->
        <!--     </el-dropdown>-->

        <el-dropdown @command="changeLanguage">
        <span class="el-dropdown-link">
          <i class="icon-globe"></i>
          {{ langName($i18n.locale) }}
        </span>
          <el-dropdown-menu>
            <div v-for="(item,i) in LANGUAGES" :key="i">
              <el-dropdown-item :command="item.key">{{ item.name }}</el-dropdown-item>
            </div>

          </el-dropdown-menu>
        </el-dropdown>
        <el-divider direction="vertical"></el-divider>
        <div class="profile_account">
          <!--       <el-avatar :size="40">-->
          <!--         <img src="../../assets/img/Avatar.png"/>-->
          <!--       </el-avatar>-->
          <div class="d-flex flex-column align-items-center">
            <span>{{ userData.firstName + ' ' + userData.lastName }}</span>
            <span style="font-size: 12px">{{ userData.roleName }}</span>
          </div>

          <el-dropdown @command="userAction">
            <span class="el-dropdown-link">
              <i class="icon-action"></i>
            </span>
            <el-dropdown-menu>
              <!--              <el-dropdown-item command="1">Action 1</el-dropdown-item>-->
              <el-dropdown-item command="2">{{ contentLocale.changepassword }}</el-dropdown-item>
              <el-dropdown-item command="3">{{ contentLocale.logout }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        </div>
      </div>
    </div>


  </div>
</template>

<script>
import TokenService from "@/services/TokenService";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header',
  data() {
    return {
      search: '',
      langs: [
        {
          key: 'uz',
          name: 'O\'zbekcha'
        },
        {
          key: 'en',
          name: 'English'
        },
        {
          key: 'ru',
          name: 'Русский'
        },
      ],
      userData: [],
      passwordModal:false,
      changePasswordForm:{
        currentPassword : null,
        newPassword:null,
      },
      repeatPassword:null,
      changePasswordFormRules:{
        currentPassword: [{
          required: true,
          trigger: 'blur',
          message: 'Поле currentPassword обязательно для заполнения'
        }],
        newPassword: [{
          required: true,
          trigger: 'blur',
          message: 'Поле newPassword обязательно для заполнения'
        }],
        repeatPassword: [
          {required: true, message: "Пожалуйста, повторите пароль", trigger: "blur"},
          {validator: this.validateRepeatPassword, trigger: "blur"}
        ]
      }
    }
  },
  methods: {
    langName(name) {
      switch (name) {
        case 'en':
          return 'En'
        case 'ru':
          return 'Ру'
        default:
          return 'Uz'
      }

    },
    validateRepeatPassword(rule, value, callback) {
      if (value === this.changePasswordForm.newPassword) {
        callback();
      } else {
        callback(new Error("Пароли не совпадают"));
      }
    },
    async getUser() {
      let userId = localStorage.getItem('userId');
      if (userId) {
        await this.$http.get(`auth/users/${userId}`)
            .then(res => {
              if (res && res.success) {
                this.userData = res.result;
              }
            })
            .catch(e => {
              this.errorNotify(e?.response?.data?.error?.message)
            })
            .finally(() => {
              this.$store.commit('loader', false);

            })
      } else {
        await this.$router.push({name: 'app-login'})
      }

    },
    changeLanguage(lang) {
      this.$store.commit('loader', true);
      this.$http.get('auth/users/changeChosenLanguage', {
        params: {
          language: lang
        }
      })
          .then(res => {
            if (res && res.success) {
              this.$i18n.locale = lang;
              localStorage.setItem('lang', lang);
              this.getLocalizedValues();
            }
          })
          .catch(e => {
            this.errorNotify(e?.response?.data?.error?.message)
          })
          .finally(() => {
            this.$store.commit('loader', false);

          })

    },
    userAction(action) {
      if (action == 3) {
        let token = TokenService.getToken();
        this.$store.commit('loader', true);
        this.$http.get('auth/users/logout', {
          params: {
            token: token
          }
        })
            .then(res => {
              if (res && res.success) {
                this.$router.push('/login')
                localStorage.clear();
              }
            })
            .catch(e => {
              this.$router.push({name: 'app-login'})
              this.errorNotify(e?.response?.data?.error?.message)
            })
            .finally(() => {
              this.$store.commit('loader', false);
            })
      }
      else if(action ==2 ){
        this.$store.commit('passwordModal',true)
      }
    },
    async getLocalizedValues() {
      try {
        const res = await this.$store.dispatch('getValuesByLang');
        this.$store.commit('changeLocale', res.result);
      } catch (e) {
        this.errorNotify(e?.response?.data?.error?.message ?? e)
      }
    },

  },
  computed: {
    LANGUAGES() {
      return this.langs.filter((lang) => lang.key !== this.$i18n.locale);
    },
  },
  created() {
    this.getUser();
  }

}
</script>

<style scoped>
.btn_success i {
  font-size: 14px !important;
}
i {
  font-size: 22px;
}

.header-right {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;

}

.header-right > div {
  margin-right: 20px;
}

.el-dropdown-link {
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>