import Vue from 'vue';
import VueRouter from 'vue-router';
import TokenService from "@/services/TokenService";
import can from "@/can";

import MainLayout from "@/layouts/MainLayout/MainLayout";

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        component: () => import('@/components/Login/login.vue'),
        name: "app-login"
    },
    {
        path: '/',
        component: MainLayout,
        children: [
            {
                path: '/',
                component: () => import('@/views/Dashboard/Dashboard.vue'),
                name: 'Dashboard'
            },
            {
                path: '/branches',
                component: () => import('@/views/Branches/Branches.vue'),
                name: 'Branches'
            },
            {
                path: '/roles',
                component: () => import('@/views/Roles/Roles.vue'),
                name: 'app-roles'
            },
            {
                path: '/modules',
                component: () => import('@/views/Modules/Modules.vue'),
                name: 'app-modules'
            },
            {
                path: '/permissions',
                component: () => import('@/views/Permissions/Permissions.vue'),
                name: 'app-permissions'
            },
            {
                path: '/users',
                component: () => import('@/views/Users/Users.vue'),
                name: 'app-users'
            },
            {
                path: '/sms',
                component: () => import('@/views/SMS/sms.vue'),
                name: 'app-sms'
            },
            {
                path: '/agreements',
                component: () => import('@/views/Agreements/Agreements.vue'),
                name: 'app-agreements'
            },
            {
                path: '/scoring',
                component: () => import('@/views/Scoring/Scoring.vue'),
                name: 'app-scoring'
            },
            {
                path: '/clients',
                component: () => import('@/views/Clients/Clients.vue'),
                name: 'app-clients'
            },
            {
                path: '/installments',
                name: 'app-installments',
                component: () => import('@/views/Installments/Installments.vue'),
            },
            {
                path: '/installments-add',
                name: 'app-upsert',
                component: () => import('@/views/Installments/Upsert.vue'),
            },
            {
                path: '/installments-view',
                name: 'app-view',
                component: () => import('@/views/Installments/view.vue'),
            },
            {
                path: '/transactions',
                name: 'app-transactions',
                component: () => import('@/views/Transactions/Transactions.vue')

            },
            {
                path: '/partners',
                name: 'app-partners',
                component: () => import('@/views/Partners/Partners.vue')

            },
            {
                path: '/localization',
                name: 'app-group',
                component: () => import('@/views/Localization/group.vue')

            },
            {
                path:'/localization/:id',
                name:'group-item',
                component:()=>import('@/views/Localization/group-item.vue')
            },
            {
                path: '/banks',
                name: 'app-banks',
                component: () => import('@/views/Banks/Banks.vue')

            },
            {
                path:'/banks/:id',
                name:'banks-bin',
                component:()=>import('@/views/Banks/BanksBin.vue')
            },
            {
                path:'/payment-system',
                name:'app-payment-system',
                component:()=>import('@/views/PaymentSystem/PaymentSystem.vue')
            },
            {
                path: '/installments',
                name: 'app-installments',
                component: () => import('@/views/Installments/Installments.vue'),
            },
            {
                path:'/installments/:id',
                name:'app-view',
                component:()=>import('@/views/Installments/view.vue')
            },


        ]
    }
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const isAuthenticated = !!TokenService.getToken()

    if (to.name !== 'app-login' && !isAuthenticated) {
        return next({name: 'app-login'})
    } else {
        if (to.meta.permission) {
            if (!can(to.meta.permission)) {
                next({path: '/login'})
            }
            next()
        }  else {
            next()
        }
    }
})

export default router;