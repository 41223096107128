<template>
  <div>
    <Sidebar/>
    <Header/>
    <div class="main-content">
      <router-view/>
    </div>
    <el-dialog
        :title="contentLocale.changepassword"
        :visible="$store.state.passwordModal"
        width="33%"
        @close="closePasswordModal"
        :close-on-click-modal="true"
        :show-close="false"
        class="my-modal"
    >
      <div class="modal-content">
        <el-form ref="myChangePasswordForm" :model="changePasswordForm" :rules="changePasswordFormRules"
                 label-width="120px" class="form-group my-form" label-position="top">
          <el-form-item :label="contentLocale.currentpassword" prop="currentPassword">
            <el-input
                :placeholder="contentLocale.currentpassword"
                v-model="changePasswordForm.currentPassword"
                show-password
            ></el-input>

          </el-form-item>
          <el-form-item :label="contentLocale.new_password" prop="newPassword">
            <el-input
                :placeholder="contentLocale.new_password"
                v-model="changePasswordForm.newPassword"
                show-password
            ></el-input>

          </el-form-item>
          <el-form-item :label="contentLocale.repeat_password" prop="repeatPassword">
            <el-input
                :placeholder="contentLocale.repeat_password"
                v-model="changePasswordForm.repeatPassword"
                show-password
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog-footer">
        <el-button class="btn_success"
                   @click="changePassword"
        >
          <i class="icon-check-circle"></i>
          <span>{{ contentLocale.save }}</span></el-button>
        <el-button @click="closePasswordModal" type="info" class="cancel_btn">{{ contentLocale.cancel }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar/Sidebar";
import Header from "@/components/Header/Header";

export default {
  name: "MainLayout",
  components: {
    Sidebar,
    Header
  },
  data() {
    return {
      changePasswordForm: {
        currentPassword: null,
        newPassword: null,
        repeatPassword: null,

      },
      changePasswordFormRules: {
        currentPassword: [{
          required: true,
          trigger: 'blur',
          message: 'Поле currentPassword обязательно для заполнения'
        }],
        newPassword: [{
          required: true,
          trigger: 'blur',
          message: 'Поле newPassword обязательно для заполнения'
        }],
        repeatPassword: [
          {required: true, message: "Пожалуйста, повторите пароль", trigger: "blur"},
          {validator: this.validateRepeatPassword, trigger: "blur"}
        ]
      }
    }
  },
  methods: {
    validateRepeatPassword(rule, value, callback) {
      if (value === this.changePasswordForm.newPassword) {
        callback();
      } else {
        callback(new Error("Пароли не совпадают"));
      }
    },
    changePassword() {
      this.$refs.myChangePasswordForm.validate(valid => {
        if (valid) {
          this.$http.post("auth/users/changePassword", this.changePasswordForm).then(res => {
            if (res && res.success) {
              this.$store.commit('passwordModal', false);
              this.successNotify();
              this.$router.push('/login')

            }
          })
              .catch(e => {
                this.errorNotify(e?.response?.data?.error?.message)
              })
              .finally(() => {
              });
        } else {
          return false;
        }
      });
    },
    closePasswordModal() {
      this.$store.commit('passwordModal', false)
    }
  },
  watch:{
    '$store.state.passwordModal':function (val){
      if (val){
        if (this.$refs.myChangePasswordForm){
          this.$refs.myChangePasswordForm.resetFields();
        }
        this.changePasswordForm = {
          currentPassword: '',
          newPassword: '',
          repeatPassword: ''
        };
      }
    }
  }
}
</script>

<style scoped>

</style>