<template>
  <div class="sidebar_view">
    <div class="adminka_logo">
      SmartBill Admin
<!--      <img src="../../assets/img/logo_merchant.png" alt="merchant_logo">-->
    </div>
    <el-col :span="24">
      <el-menu
          :default-active="getActiveMenuItem"
          class="el-menu-vertical-demo"
          :router="true"
      >
        <el-menu-item index="/" :route="{name:'Dashboard'}">
          <i class="icon-dashboard"></i>
          <span>{{ contentLocale.dashboard }}</span>
        </el-menu-item>
        <el-menu-item index="/partners" :route="{name:'app-partners'}" v-if="can('getpartners')">
          <i class="el-icon-folder"></i>
          <span>{{ contentLocale.partners }}</span>
        </el-menu-item>
        <el-menu-item index="/agreements" :route="{name:'app-agreements'}" v-if="can('getagreements')">
          <i class="icon-vector-pen"></i>
          <span>{{ contentLocale.agreements }}</span>
        </el-menu-item>
        <el-menu-item index="/users" :route="{name:'app-users'}" v-if="can('getusers')">
          <i class="icon-users"></i>
          <span>{{ contentLocale.users }}</span>
        </el-menu-item>
        <el-menu-item index="/banks" :route="{name:'app-banks'}" v-if="can('getbanks')">
          <i class="icon-dashboard"></i>
          <span>{{ contentLocale.banks }}</span>
        </el-menu-item>
        <el-menu-item index="/payment-system" :route="{name:'app-payment-system'}" v-if="can('getpaymentsystems')">
          <i class="icon-dashboard"></i>
          <span>{{ contentLocale.paymentsystem }}</span>
        </el-menu-item>
        <el-menu-item index="/branches" :route="{name:'Branches'}" v-if="can('getbranches')">
          <i class="icon-branches"></i>
          <span>{{ contentLocale.branches }}</span>
        </el-menu-item>

        <el-menu-item index="/modules" :route="{name:'app-modules'}" v-if="can('getmodules')">
          <i class="el-icon-folder"></i>
          <span>{{ contentLocale.modules }}</span>
        </el-menu-item>
        <el-menu-item index="/roles" :route="{name:'app-roles'}" v-if="can('getroles')">
          <i class="icon-angle"></i>
          <span>{{ contentLocale.roles }}</span>
        </el-menu-item>
        <el-menu-item index="/permissions" :route="{name:'app-permissions'}" v-if="can('getpermissions')">
          <i class="el-icon-s-operation"></i>
          <span>{{ contentLocale.permissions }}</span>
        </el-menu-item>
        <el-menu-item index="/localization" :route="{name:'app-group'}" v-if="can('getgroups')">
          <i class="el-icon-s-operation"></i>
          <span>{{ contentLocale.localization }}</span>
        </el-menu-item>

<!--        <el-menu-item index="/sms" :route="{name:'app-sms'}">-->
<!--          <i class="icon-envelope-open"></i>-->
<!--          <span>SMS templates</span>-->
<!--        </el-menu-item>-->

<!--        <el-menu-item index="/scoring" :route="{name:'app-scoring'}">-->
<!--          <i class="icon-life-preserver"></i>-->
<!--          <span>Scoring</span>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="/clients" :route="{name:'app-clients'}">-->
<!--          <i class="icon-clients"></i>-->
<!--          <span>Clients</span>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="/installments" :route="{name:'app-installments'}">-->
<!--          <i class="icon-Installments"></i>-->
<!--          <span>Installments</span>-->
<!--        </el-menu-item>-->
        <el-menu-item index="/installments" :route="{name:'app-installments'}" v-if="can('getinstallments')">
          <i class="icon-Installments"></i>
          <span>{{ contentLocale.installments	 }}</span>
        </el-menu-item>
        <el-menu-item index="/transactions" :route="{name:'app-transactions'}" v-if="can('gettransactions')">
          <i class="icon-arrow-left-right"></i>
          <span>{{ contentLocale.transactions }}</span>
        </el-menu-item>
<!--        <el-menu-item index="/test" :route="{name:'test'}">-->
<!--          <i class="icon-credit-card"></i>-->
<!--          <span>Card refill</span>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="/test" :route="{name:'test'}">-->
<!--          <i class="icon-calculator"></i>-->
<!--          <span>Fiscal signs</span>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="/test" :route="{name:'test'}">-->
<!--          <i class="icon-statistics"></i>-->
<!--          <span>Statistics</span>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="/test" :route="{name:'test'}" class="badge-content">-->
<!--          <div>-->
<!--            <i class="icon-exclamation-circle"></i>-->
<!--            <span>Reports</span>-->
<!--          </div>-->
<!--          <el-badge :value="6" class="item" type="primary">-->
<!--          </el-badge>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="/test" :route="{name:'test'}">-->
<!--          <i class="icon-angle"></i>-->
<!--          <span>SMS reports</span>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="/test" :route="{name:'test'}">-->
<!--          <i class="icon-graph-up"></i>-->
<!--          <span>Activity logs</span>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="/test" :route="{name:'test'}">-->
<!--          <i class="icon-info-circle"></i>-->
<!--          <span>FAQ</span>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="/test" :route="{name:'test'}">-->
<!--          <i class="icon-question-circle"></i>-->
<!--          <span>Instructions</span>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="/test" :route="{name:'test'}">-->
<!--          <i class="icon-contact"></i>-->
<!--          <span>Contact us</span>-->
<!--        </el-menu-item>-->
        <div class="sidebar-footer">
<!--          <span>Powered by: </span>-->
          <img src="../../assets/img/dark_logo.png" alt="">
        </div>
      </el-menu>


    </el-col>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Sidebar",
  data() {
    return {}
  },
  computed: {
    getActiveMenuItem() {
      return this.$route.path
    }
  }
}
</script>

<style scoped>
.el-menu {
  height: calc(100vh - 134px);
  overflow-y: auto;
}

.el-menu-item {
  font-weight: 500;
}

.el-menu-item.is-active {
  border-left: 3px solid rgba(18, 96, 211, 1);
  font-weight: 600;
  background: linear-gradient(90deg, #519dde 0%, rgba(0, 103, 204, 0) 22.2%);
}

.el-menu-item.is-active i::before {
  color: #1260D3;
}

.el-menu-item i {
  font-size: 18px;
  margin-right: 10px;
}

.sidebar-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 207px;
  height: 52px;
  background-color: #DCEEFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-footer img {
  width: 120px;
}

.badge-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar-footer span {
  font-size: 12px;
  margin-right: 8px;
}

.icon-branches:before {
  color: #7C91B1;
}

</style>