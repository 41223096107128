import Vue from 'vue';
import App from './App.vue';
import Element from "element-ui";
import locale from "element-ui/lib/locale";
import ruLang from "element-ui/lib/locale/lang/ru-RU";
import "element-ui/lib/theme-chalk/index.css";
import router from "@/router";
import './mixins/filter';
import http from "./plugins/axios";
import Notifications from 'vue-notification';
import NotificationMixin from "./mixins/Notifications";
import helper from "@/mixins/helper";
import Vuex from 'vuex';
import store from "@/store/index";
import i18n from "@/plugins/i18n";
import VueMask from 'v-mask';
import can from "@/can";



Vue.use(Element);
Vue.use(VueMask);
Vue.use(Notifications);
Vue.prototype.$http = http;
Vue.use(require('vue-moment'));
Vue.mixin(NotificationMixin);
Vue.mixin(helper);
Vue.use(Vuex);
Vue.mixin({methods: {can}});


locale.use(ruLang);
new Vue({
    el:"#app",
    router,
    store,
    i18n,
    render: h => h(App)
})