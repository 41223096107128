import Moment from "moment/moment";

export default {
    data(){
      return{
         body:{
             pageNumber:0,
             pageSize:0
         },
      }
    },
    methods: {
        getSelectList(url,list = []){
            this.$http.post(url, this.body).then(res =>{
                list.push(...res.result.data);
            })
                .catch(e=>{
                    this.errorNotify(e?.response?.data?.error?.message)
                })
        },
        getStatusName(status) {
            switch (status) {
                case 1:
                    return this.contentLocale.active;
                case 2:
                    return this.contentLocale.pending;
                case 3:
                    return this.contentLocale.inactive;
                case 4:
                    return this.contentLocale.blocked;
                case 5:
                    return this.contentLocale.succees;
                case 6:
                    return this.contentLocale.deleted;
                case 7:
                    return this.contentLocale.failed;
                default:
                    return this.contentLocale.created;

            }
        },
        commonDateFormat(val){
            return Moment(val).isValid() ? Moment(val).format('DD.MM.YYYY HH:mm') : '';
        },
        commonDateYearFormat(val){
            return Moment(val).isValid() ? Moment(val).format('DD.MM.YYYY') : '';
        },
        financialFormatMoney(value){
            let val = (value/1).toFixed(0)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        },
        differentDay(value,different){
            let a = Moment(value);
            let b = Moment(different);
            return (a.diff(b, 'days'));
        },
        formatPhoneNumber(value){
            let cleanedNumber = value.replace(/\D/g, '');
            let formattedNumber = cleanedNumber.replace(
                /^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/,
                '+$1 $2 $3 $4 $5'
            );

            return formattedNumber;
        },
        unmask(value) {
            return value.replace(/[^0-9]/g, '');
        },
        getNameById(id,lists){
            let item = lists.find(item => item.id === id)
            return item ? item.name : id
        },
        formatMoney(value) {
            if (value){
                value = String(value).replace(/[^0-9]/g, '');
                const parts = value.split('.');
                const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                return parts.length > 1 ? `${integerPart}.${parts[1]}` : integerPart;
            }

        },
        moneyFormat(value){
            if (value){
                if (isNaN(value)) return 'Invalid number';

                // Convert the number to a string, split by the decimal point
                let parts = Number(value)?.toFixed(2).split('.');

                // Use a regular expression to add spaces as thousand separators
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

                // Join the integer part with the fractional part using a period
                return parts.join('.');
            }
        }
    },
    computed:{
        statuses(){
            return[
                {
                    id: 3,
                    name: this.contentLocale.inactive
                },
                {
                    id: 1,
                    name: this.contentLocale.active
                },
                {
                    id: 0,
                    name: this.contentLocale.created
                },
                {
                    id: 2,
                    name: this.contentLocale.pending
                },
                {
                    id: 4,
                    name: this.contentLocale.blocked
                },
                {
                    id: 5,
                    name: this.contentLocale.succees
                },
                {
                    id: 6,
                    name: this.contentLocale.deleted
                },
                {
                    id: 7,
                    name: this.contentLocale.failed
                },
            ]
        },
        contentLocale(){
            return this.$store.getters.getContentLocale
        },
        acceptedCardTypes(){
            return [
                {
                    id:0,
                    name:this.contentLocale.all
                },
                {
                    id:1,
                    name:this.contentLocale.privateorpride
                },
                {
                    id:2,
                    name:this.contentLocale.corporate
                }
            ]
        },
        eposTypes(){
            return[
                {
                    id:0,
                    name:this.contentLocale.main
                },
                {
                    id:1,
                    name:this.contentLocale.commission
                },
                {
                    id:2,
                    name:this.contentLocale.credit
                },
                {
                    id:3,
                    name:this.contentLocale.autopayment
                }
            ]
        },
        emitterTypes(){
            return[
                {
                    id:0,
                    name:this.contentLocale.uzcard
                },
                {
                    id:1,
                    name:this.contentLocale.humo
                }
            ]
        },
        terminalTypes(){
            return[
                {
                    id:1,
                    name:this.contentLocale.debet
                },
                {
                    id:2,
                    name:this.contentLocale.credit
                },
                {
                    id:3,
                    name:'P2P'
                }
            ]
        },
        partnerTypes(){
            return[
                {
                    id: 0,
                    name: this.contentLocale.individual
                },
                {
                    id: 1,
                    name: this.contentLocale.organization
                }
            ]
        }
    },
}